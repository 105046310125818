import React, { FC } from 'react';
import styled from 'styled-components';
import VideoReviewsCard from './VideoReviewsCard';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { tablet, useQuery } from 'styles/breakpoints';
import { title } from 'process';
import { DynamicImage } from 'components/images/DynamicImage';

const cardData = [
  {
    img: 'reviews/r2.png',
    title: 'Love what I see in the mirror',
    review:
      '"I gained so much weight since college. I used to be very active, run field, be fit and really proud of my body. But the years after college, stress looking for a job and moving all around the country made me gain so much weight. Thanks to no.Diet I finally feel and look healthy again.”',
    name: 'Simone',
  },
  {
    img: 'reviews/r3.png',
    title: 'Fixed my digestion in 4 weeks',
    review:
      '"This meal plan has done wonders for my digestion and overall health. I’ve lost 14 lbs in 4 weeks, but the biggest change is how much better I feel inside and out. My stomach issues are gone, my energy levels are up, and I finally feel comfortable in my own skin.”',
    name: 'Rosa',
  },
  {
    img: 'reviews/r4.png',
    title: 'Finally reached my goals',
    review:
      '“For years, I felt stuck with my weight and constantly felt tired. Since starting this Mediterranean meal plan, everything has changed! In just 5 weeks, I’ve shed 22 lbs, but the best part is how balanced and clear-headed I feel every day.”',
    name: 'Clara',
  },
  {
    img: 'reviews/r5.png',
    title: 'So delicious!',
    review:
      '"I’ve always struggled with emotional eating, but this meal plan has made such a difference. The food is so flavorful and satisfying that I don’t feel the need to binge anymore. My relationship with food feels healthier than it ever has. I feel more positive and confident in my day-to-day life too!"',
    name: 'Anika',
  },
  {
    img: 'reviews/r6.png',
    title: 'Never felt this energetic in my life',
    review:
      '"I used to feel sluggish all the time, but now I wake up feeling refreshed and excited for the day. I’ve lost 20 lbs in 4 weeks on this Mediterranean plan, but the changes go beyond the scale. My skin is clearer, my sleep has improved, and I’m just so much happier.”',
    name: 'Eliana',
  },
];

const CardsSection: FC = () => {
  const { isTablet } = useQuery();

  return (
    <Container>
      <Title>
        Proven results from our
        <br />
        customers
      </Title>
      <Subtitle>See the impact of no.Diet plans</Subtitle>
      <CarouselContainer>
        <SwiperStyled
          modules={[Pagination]}
          pagination={{ clickable: true }}
          allowTouchMove={true}
          effect="slide"
          slidesPerView={'auto'}
          spaceBetween={isTablet ? 16 : 24}
        >
          {cardData.map((review, index) => (
            <SwiperSlide key={index}>
              <Card>
                <DynamicImage src={review.img} alt={review.title} />
                <Inner>
                  <CardTitle>{review.title}</CardTitle>
                  <Review>{review.review}</Review>
                  <Name>{review.name}</Name>
                </Inner>
              </Card>
            </SwiperSlide>
          ))}
        </SwiperStyled>
      </CarouselContainer>
    </Container>
  );
};

export default CardsSection;

const Inner = styled.section`
  display: flex;
  flex-direction: column;
  padding: 1rem 0.75rem 0.75rem;
  width: 100%;
`;

const Review = styled.p`
  color: #000;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* height: 6.25rem; */
  padding: 0.5rem 0 0.75rem;
`;

const Name = styled.p`
  color: #141515;
  font-family: 'Manrope', sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  padding: 3.5rem 1rem;
  flex-direction: column;
  align-items: center;
  background: #fff;
  @media ${tablet} {
    padding: 2.5rem 1rem;
  }
`;

const Card = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 0.75rem;
  border: 1px solid #f4f4f4;
  background: #faf9f7;
  max-width: 21.4375rem;
  width: 100%;
  overflow: hidden;

  @media ${tablet} {
  }
`;

const CardTitle = styled.p`
  color: #000;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  width: 100%;
`;

const Title = styled.p`
  color: #141515;
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;

  @media ${tablet} {
    font-size: 1.5rem;
  }
`;

const Subtitle = styled.p`
  color: #141515;
  text-align: center;
  font-family: 'Manrope', sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  padding: 0.5rem 0 2rem;
  @media ${tablet} {
    padding: 0.5rem 0 1.5rem;
  }
`;

const CarouselContainer = styled.div`
  width: 100%;
  max-width: 67.3125rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SwiperStyled = styled(Swiper)`
  .swiper-pagination {
    position: relative !important;
    width: 100%;
  }

  .swiper-slide {
    width: 21.4375rem !important;
    margin-bottom: 26px;

    @media ${tablet} {
      margin-bottom: 18px;
    }
  }

  .swiper-pagination-bullet {
    opacity: unset;
    margin: 0 0.25rem;
    background-color: #c9eac7;
    &-active {
      background-color: #03a62c;
      width: 8px;
    }
  }
`;
