import React, { FC } from 'react';
import styled from 'styled-components';
import VideoReviewsCard from './VideoReviewsCard';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { tablet, useQuery } from 'styles/breakpoints';
import Full from 'assets/icons/full-trustpilot.svg';
import { DynamicImage } from 'components/images/DynamicImage';
import Verified from 'assets/icons/verified.svg';
import { useRouter } from 'apis/history';
import PrimaryButton from 'components/PrimaryButton';
import Shield from 'assets/icons/shield.svg';

const cardData = [
  {
    img: 'reviews/rs1.png',
    review:
      '“I’ve been on the Mediterranean meal plan for half a year, and the transformation has been incredible. I’ve shed 35 pounds, but the benefits go far beyond just the weight. I feel more energized, my skin looks healthier, and my digestion has never been better. It’s not just about the food; it’s a whole new way of living that’s brought balance to my life."',
    name: 'Katie',
  },
  {
    img: 'reviews/rs2.png',
    review:
      '"I have struggled with eating healthy in the past. I wanted to try vegan and vegetarian, but the food just wasn’t delicious. So I would go back to eating junk food and gaining weight. But this plan changed everything. The food is delicious and healthy at the same time. And I’m losing weight while eating amazing, easy to cook food.”',
    name: 'Sandra',
  },
  {
    img: 'reviews/rs3.png',
    review:
      'I’ve been following this Mediterranean meal plan for 6 months now, and the results have been nothing short of life-changing. I’ve lost 35 lbs, but beyond the weight loss, I’ve gained so much more. My energy levels are higher than they’ve been in years, my skin is glowing, and my digestion has improved dramatically. This is more than a diet—it’s a lifestyle."',
    name: 'Nadia',
  },
  {
    img: 'reviews/rs4.png',
    review:
      '"As a nurse with long shifts, I’ve struggled to focus on my health. This Mediterranean meal plan has been a game-changer. I’ve lost 18 lbs in 5 weeks, and I feel more energized and focused during my shifts. The meals are easy to prep and fit perfectly into my busy schedule.”',
    name: 'Maya',
  },
  {
    img: 'reviews/rs5.png',
    review:
      'This plan has helped me not only lose weight but also feel more mentally balanced. I’ve dropped 16 lbs in a month, but I’ve also noticed that I’m less anxious and more present with my clients. It’s like this diet is feeding both my body and my soul.”',
    name: 'Carmen',
  },
  {
    img: 'reviews/rs6.png',
    review:
      'This meal plan is exactly what I needed to get my health on track. I’ve dropped 20 lbs in 6 weeks, and the biggest surprise? My productivity at work has skyrocketed. I feel sharper, more focused, and my energy doesn’t crash in the middle of the day like it used to.”',
    name: 'Mateo',
  },
];

const SuccessStoriesSection: FC = () => {
  const { isTablet } = useQuery();
  const { goToLanding } = useRouter();
  return (
    <Container>
      <Title>
        no.Diet review <span>highlights</span>
      </Title>
      <Subtitle>Thousands of happy and healthy customers</Subtitle>
      <CarouselContainer>
        <SwiperStyled
          modules={[Pagination]}
          pagination={{ clickable: true }}
          allowTouchMove={true}
          effect="slide"
          slidesPerView={'auto'}
          spaceBetween={isTablet ? 16 : 24}
        >
          {cardData.map((review, index) => (
            <SwiperSlide key={index}>
              <Card>
                <DynamicImage src={review.img} alt="" />
                <Inner>
                  <BottomContainer>
                    <BottomInner>
                      <NameContainer>
                        <Name>{review.name}</Name>
                        <Shield />
                      </NameContainer>
                      <Verified />
                    </BottomInner>
                    <Full />
                  </BottomContainer>
                  <CardText>{review.review}</CardText>
                </Inner>
              </Card>
            </SwiperSlide>
          ))}
        </SwiperStyled>
      </CarouselContainer>

      <StyledButton onClick={() => goToLanding()}>
        Start your journey
      </StyledButton>
    </Container>
  );
};

export default SuccessStoriesSection;

const StyledButton = styled(PrimaryButton)`
  max-width: 21.4375rem;
  margin: 2rem auto 0;
  @media ${tablet} {
    margin: 1.5rem auto 0;
  }
`;

const Container = styled.div`
  display: flex;
  padding: 3.5rem 1rem;
  flex-direction: column;
  align-items: center;
  background: #fff;
  @media ${tablet} {
    padding: 2.5rem 1rem;
  }
`;

const Title = styled.p`
  color: #141515;
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;

  span {
    color: #141515;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
  }

  @media ${tablet} {
    font-size: 1.5rem;

    span {
      display: block;
      font-size: 1.5rem;
    }
  }
`;

const Subtitle = styled.p`
  color: #141515;
  text-align: center;
  font-family: 'Manrope', sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  padding: 0.5rem 0 0;
  @media ${tablet} {
    padding: 0.5rem 0 0;
  }
`;

const CarouselContainer = styled.div`
  width: 100%;
  max-width: 67.3125rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SwiperStyled = styled(Swiper)`
  .swiper-pagination {
    position: relative !important;
    width: 100%;
  }

  .swiper-slide {
    width: 21.4375rem !important;
    margin-bottom: 24px;
  }

  .swiper-pagination-bullet {
    opacity: unset;
    margin: 0 0.25rem;
    background-color: #c9eac7;
    &-active {
      background-color: #03a62c;
      width: 8px;
    }
  }
`;

const Card = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 0.75rem;
  border: 1px solid #f4f4f4;
  background: #faf9f7;
  max-width: 21.375rem;
  width: 100%;
  margin: 2.5rem 0 1.5rem;
  overflow: hidden;

  @media ${tablet} {
    margin: 2rem 0 1rem;
  }
`;

const CardText = styled.p`
  color: #434444;
  font-family: 'Manrope', sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  height: 10rem;
  @media ${tablet} {
  }
`;

const BottomContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0 0.75rem;
  width: 100%;
  @media ${tablet} {
  }
`;

const Name = styled.p`
  color: #141515;
  text-align: center;
  font-family: 'Manrope', sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
`;

const BottomInner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.375rem;
  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const Inner = styled.section`
  display: flex;
  flex-direction: column;
  padding: 0 0.75rem 0.75rem;
  width: 100%;
`;

const NameContainer = styled.div`
  display: flex;
  gap: 0.375rem;
  align-items: center;
`;
