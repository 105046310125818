import React, { FC } from 'react';
import styled from 'styled-components';
import VideoReviewsCard from './VideoReviewsCard';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { tablet, useQuery } from 'styles/breakpoints';
import Full from 'assets/icons/full-trustpilot.svg';

const cardData = [
  {
    review:
      'The most delicious meal plan I have ever tried. Every time I eat I feel like I’m on vacation.',
    name: 'Annie C.',
  },
  {
    review:
      'Weight- down. Cholesterol - down. Energy and self confidence - up. Crazy results in 2 months.',
    name: 'Jake P.',
  },
  {
    review:
      'These meals are a game-changer! I’ve lost weight without even feeling like I’m dieting.',
    name: 'Jenna F.',
  },
  {
    review:
      'Absolutely love this meal plan! Lost 20 lbs in just a couple of weeks, and I feel fantastic.',
    name: 'Emily S.',
  },
  {
    review:
      'Delicious, healthy, and effective! my cholesterol is down and I’m feeling great.',
    name: 'Alex R.',
  },
  {
    review:
      'Best plan ever! I lost 18 lbs quickly, and the meals are so filling and tasty.',
    name: 'Olivia G.',
  },
];

const SatisfiedUsersSection: FC = () => {
  const { isTablet } = useQuery();

  return (
    <Container>
      <Title>12M+ satisfied users</Title>
      <Subtitle>Discover how no.Diet changed their lives</Subtitle>
      <CarouselContainer>
        <SwiperStyled
          modules={[Pagination]}
          pagination={{ clickable: true }}
          allowTouchMove={true}
          effect="slide"
          slidesPerView={'auto'}
          spaceBetween={isTablet ? 16 : 24}
        >
          {cardData.map((review, index) => (
            <SwiperSlide key={index}>
              <Card>
                <Inner>
                  <Name>{review.name}</Name>
                  <Full />
                </Inner>
                <Review>{review.review}</Review>
              </Card>
            </SwiperSlide>
          ))}
        </SwiperStyled>
      </CarouselContainer>
    </Container>
  );
};

export default SatisfiedUsersSection;

const Inner = styled.section`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Review = styled.p`
  color: #434444;
  font-family: 'Manrope', sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  height: 2.5rem;
`;

const Name = styled.p`
  color: #141515;
  font-family: 'Manrope', sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
`;

const Container = styled.div`
  display: flex;
  padding: 3.5rem 1rem;
  flex-direction: column;
  align-items: center;
  background: #faf9f7;
  @media ${tablet} {
    padding: 2.5rem 1rem;
  }
`;

const Card = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0.75rem;
  border-radius: 0.75rem;
  border: 1px solid #f4f4f4;
  background: #fff;
  padding: 1rem;
  max-width: 21.4375rem;
  width: 100%;
  overflow: hidden;
  @media ${tablet} {
  }
`;

const Title = styled.p`
  color: #000;
  text-align: center;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  @media ${tablet} {
    font-size: 1.5rem;
  }
`;

const Subtitle = styled.p`
  color: #000;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  padding: 0.5rem 0 2rem;
  @media ${tablet} {
    padding: 0.5rem 0 1.5rem;
  }
`;

const CarouselContainer = styled.div`
  width: 100%;
  max-width: 67.3125rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SwiperStyled = styled(Swiper)`
  .swiper-pagination {
    position: relative !important;
    width: 100%;
  }

  .swiper-slide {
    width: 21.4375rem !important;
    margin-bottom: 26px;

    @media ${tablet} {
      margin-bottom: 18px;
    }
  }

  .swiper-pagination-bullet {
    opacity: unset;
    margin: 0 0.25rem;
    background-color: #c9eac7;
    &-active {
      background-color: #03a62c;
      width: 8px;
    }
  }
`;
